import { DownloadIcon } from '@assets/icons'
import { cardPalette } from '@assets/themes/palette'
import theme from '@assets/themes/theme'
import { IPaidBillsProps } from '@interfaces/bills'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import {
  Box,
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

const BillsIcon = styled(InsertDriveFileOutlinedIcon)({
  width: '48px',
  height: '48px',
  padding: '10px',
  backgroundColor: '#EDEEED',
  border: '1px solid #D1D4D1',
  borderRadius: '12px',
})

const CustomTableRow = styled(TableRow)({
  '&:hover': {
    backgroundColor: cardPalette.stackBgColor,
    boxShadow: `0 0 0 10px ${cardPalette.stackBgColor}`,
    borderRadius: theme.borderRadius.small,
    cursor: 'pointer',
  },
})

const CustomTableCell = styled(TableCell)({
  padding: 0,
  border: 'none',
  textAlign: 'left',
  verticalAlign: 'center',
})

const TableComponent = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  return (
    <Table className="invoiceDesktopContainer" sx={{ borderCollapse: 'separate', borderSpacing: '0 24px' }}>
      <TableHead>
        <TableRow>
          <CustomTableCell>
            <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
              {t('invoices.invoice_date')}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
              {t('invoices.invoice_number')}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
              {t('invoices.invoice_amount')}
            </Typography>
          </CustomTableCell>
          <CustomTableCell>
            <Typography variant="bodySmallEmphasized" color={theme.palette.text.secondary}>
              {t('invoices.invoice_document')}
            </Typography>
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  )
}

const InvoiceRowDesktop = ({ row, multipleContracts }: { row: IPaidBillsProps; multipleContracts: boolean }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isCreditNote = row.amountTotal.includes('-')
  return (
    <>
      <CustomTableCell>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          columnGap="24px"
          id={row.invoiceId}
          onClick={() =>
            navigate(`${t('navigation_url.invoices')}/${row.invoiceId}`, {
              state: { invoiceId: row.invoiceId },
            })
          }
        >
          <BillsIcon />
          <Box>
            <Typography variant="bodySmallEmphasized" sx={{ margin: 0 }}>
              {t(isCreditNote ? 'invoices.invoice_payout' : 'invoices.invoice') +
                ` (${dayjs(row.billingPeriodFromDate).format('DD.MM.YY')} - ${dayjs(row.billingPeriodToDate).format(
                  'DD.MM.YY'
                )})`}
            </Typography>
            <Show when={multipleContracts}>
              <Typography
                variant="bodySmall"
                sx={{ margin: 0, fontFamily: 'GothamBook', fontWeight: 400, color: theme.palette.text.secondary }}
              >
                {row.contractId}
              </Typography>
            </Show>
          </Box>
        </Stack>
      </CustomTableCell>
      <CustomTableCell>
        <Typography
          variant="bodySmall"
          sx={{
            fontFamily: 'GothamBook',
            fontWeight: 400,
            color: theme.palette.text.secondary,
          }}
        >
          {row.invoiceId}
        </Typography>
      </CustomTableCell>

      <CustomTableCell>
        <Typography variant="bodySmallEmphasized" color={isCreditNote ? theme.palette.primary.main : 'auto'}>{`${
          isCreditNote ? '+' : ''
        }${isCreditNote ? row.amountTotal.slice(1) : row.amountTotal} ${row.currency}`}</Typography>
      </CustomTableCell>

      <CustomTableCell>
        <Button
          disableRipple
          size="medium"
          variant="text"
          sx={{
            p: 0,
            minWidth: 'max-content',
            '.MuiButton-startIcon': {
              transition: 'color .15s ease-out, background-color .15s ease-out',
              borderRadius: '50%',
              padding: '4px',
              '& > :nth-of-type(1)': {
                width: '18px',
                height: '18px',
              },
            },
            '&:hover .MuiButton-startIcon': {
              color: '#FFF',
              backgroundColor: theme.palette.primary.main,
              transition: 'color .15s ease-out, background-color .15s ease-out',
            },
          }}
          startIcon={<DownloadIcon />}
        >
          PDF
        </Button>
      </CustomTableCell>
    </>
  )
}

const InvoiceRowMobile = ({ row, multipleContracts }: { row: IPaidBillsProps; multipleContracts: boolean }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isCreditNote = row.amountTotal.includes('-')
  return (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="start"
      columnGap="16px"
      sx={{ cursor: 'pointer' }}
      id={row.invoiceId}
      onClick={() =>
        navigate(`${t('navigation_url.invoices')}/${row.invoiceId}`, {
          state: { invoiceId: row.invoiceId },
        })
      }
    >
      <BillsIcon />
      <Box>
        <Typography variant="bodyRegularEmphasized" sx={{ margin: 0 }}>
          {t(isCreditNote ? 'invoices.invoice_payout' : 'invoices.invoice') +
            ` (${dayjs(row.billingPeriodFromDate).format('DD.MM.YY')} - ${dayjs(row.billingPeriodToDate).format(
              'DD.MM.YY'
            )})`}
        </Typography>
        <Typography
          variant="caption"
          sx={{ margin: 0, fontWeight: 400, fontSize: { xs: '14px' }, color: theme.palette.text.secondary }}
        >
          {`${t(isCreditNote ? 'invoices.invoice_credit_note_number' : 'invoices.invoice_nr')} ${row.invoiceId}`}
        </Typography>
        <Show when={multipleContracts}>
          <Typography
            variant="caption"
            sx={{ margin: 0, fontWeight: 400, fontSize: { xs: '14px' }, color: theme.palette.text.secondary }}
          >
            {row.contractId}
          </Typography>
        </Show>
        <Show when={isCreditNote}>
          <Stack direction="row" justifyContent="left">
            <Typography
              variant="bodySmallEmphasized"
              component="span"
              sx={{ margin: 0, fontWeight: 400, fontSize: { xs: '14px' }, color: theme.palette.text.secondary }}
            >
              {t('invoices.invoice_amount')}
            </Typography>
            <Typography
              component="span"
              variant="bodySmallEmphasized"
              sx={{ margin: 0, ml: 1, color: theme.palette.primary.main }}
            >
              {`+${row.amountTotal.slice(1)} ${row.currency}`}
            </Typography>
          </Stack>
        </Show>
        <Show when={!isCreditNote}>
          <Typography variant="bodySmallEmphasized" sx={{ margin: 0, color: theme.palette.text.secondary }}>
            {`${t('invoices.invoice_amount')} ${row.amountTotal} ${row.currency}`}
          </Typography>
        </Show>
      </Box>
    </Stack>
  )
}

const DtpPaidInvoiceItem = ({ data, multipleContracts }: { data: IPaidBillsProps[]; multipleContracts: boolean }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      {isMobile ? (
        <Stack direction="column" spacing={1.5} divider={<Divider />}>
          {data.map((row, index) => (
            <Box key={index + 1} className="invoiceMobileContainer">
              <InvoiceRowMobile row={row} multipleContracts={multipleContracts} />
            </Box>
          ))}
        </Stack>
      ) : (
        <TableComponent>
          {data.map((row, index) => (
            <CustomTableRow key={index + 1}>
              <InvoiceRowDesktop row={row} multipleContracts={multipleContracts} />
            </CustomTableRow>
          ))}
        </TableComponent>
      )}
    </>
  )
}

export default DtpPaidInvoiceItem
