import { DtpContractDropdown } from '@components/DtpContractDropdown/DtpContractDropdown'
import { ISelectItem } from '@components/DtpDropdown/DtpDropdown'
import DtpPaidInvoiceAccordion from '@components/DtpPaidInvoiceAccordion/DtpPaidInvoiceAccordion'
import { IAddOn } from '@hooks/useLocations/useLocationsData'
import { IInvoice } from '@interfaces/invoices'
import { Box, Typography } from '@mui/material'
import Show from '@src/ui/wrappers/Show/Show'
import dayjs from 'dayjs'
import { useState, SyntheticEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { filterContracts } from './DtpPaidInvoiceAccordionGroupUtills'

export interface IDtpPaidInvoiceAccordionGroupProps {
  invoices: [string, IInvoice[]][]
  activeLocations: ISelectItem<IAddOn>[]
  expiredLocations: ISelectItem<IAddOn>[]
}

const DtpPaidInvoiceAccordionGroup = ({
  invoices,
  activeLocations,
  expiredLocations,
}: IDtpPaidInvoiceAccordionGroupProps) => {
  const { t } = useTranslation()

  const allContractsItem = {
    label: t('invoices.all_contracts'),
    value: '0',
    addOn: {
      gpnr: '',
      supplyStartDate: '',
      supplyEndDate: '',
      meterId: '',
      contractId: '0',
    },
  }

  const [expanded, setExpanded] = useState<string | false>(String(dayjs().year()))
  const [selectedItem, setSelectedItem] = useState<ISelectItem<IAddOn>>(allContractsItem)

  const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const activeContracts = activeLocations.filter((contract) => contract.addOn?.contractId)
  const expiredContracts = expiredLocations.filter((contract) => contract.addOn?.contractId)

  const hasMultipleContracts = activeContracts.length + expiredContracts.length > 1

  if (hasMultipleContracts) {
    activeContracts.unshift(allContractsItem)
  }

  const filteredInvoices = useMemo<[string, IInvoice[]][]>(
    () => filterContracts(invoices, selectedItem),
    [invoices, selectedItem]
  )

  return (
    <>
      <Box sx={{ mb: { xs: 3, sm: 4 } }}>
        <Typography variant="heading4">{t('invoices.invoice_status_paid')}</Typography>
        <Show when={hasMultipleContracts}>
          <Box mt={3}>
            <DtpContractDropdown
              activeContracts={activeContracts}
              expiredContracts={expiredContracts}
              value={selectedItem.value}
              onLocationsSelect={setSelectedItem}
            />
          </Box>
        </Show>
      </Box>
      <Box>
        <Show when={!!filteredInvoices.length}>
          {filteredInvoices.map(([year, invoice]) => (
            <DtpPaidInvoiceAccordion
              key={year}
              expanded={expanded}
              year={year}
              handleChange={handleChange(year)}
              data={invoice}
              multipleContracts={hasMultipleContracts}
            />
          ))}
        </Show>
        <Show when={!filteredInvoices.length}>
          <Typography variant="bodyRegular">{t('invoices.no_paid_invoices_yet')}</Typography>
        </Show>
      </Box>
    </>
  )
}

export default DtpPaidInvoiceAccordionGroup
